import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { message } from "antd";
import { setEnableUser, setErrorMess, showAlert } from "../redux/feature";
import { fetchLogout } from "../redux/services/logout";

function GoAlert({ uid }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const alertProps = {
    message: useSelector((state) => state.alert.message),
    code: useSelector((state) => state.alert.code),
    type: useSelector((state) => state.alert.type),
  };

  // for Alert

  useEffect(() => {
    if (alertProps?.message && alertProps?.message.length) {
      goAlert();
    }
  }, [alert]);

  const goAlert = () => {
    let type = alertProps.type;
    if (alertProps.message[0] && alertProps.message[0][0] !== undefined) {
      _.forEach(alertProps.message, function (value) {
        if (value) {
          if (
            value[0] === "market.trade.not_permitted" ||
            value[0] === "resource.user.no_activity" ||
            value[0] === "account.follower.trades_ongoing"
          ) {
            return;
          }

          if (type === "error") {
            if (value[0] === "authz.csrf_token_empty") {
              return;
            }
            // if (value[0] === "authz.invalid_session") {
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 500);
            // }
            // if (value[0] === "authz.client_session_mismatch") {
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 500);
            // }
            // if (value[0] === "authz.csrf_token_mismatch") {
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 500);
            // }
            if (value[0] === "authz.user_not_active") {
              dispatch(setErrorMess(true));
            }
            if (value[0] === "authz.csrf_token_in_valid" && uid) {
              setTimeout(() => {
                dispatch(fetchLogout({ uid, t }));
                window.location.reload();
              }, 500);
            }

            if (value[0] === "authz.csrf_token_in_valid") {
              setTimeout(() => {
                message.error(t(value));
              }, 500);
              return;
            }

            if (
              value[0] === "identity.session.not_active" ||
              value[0] === "identity.session.disabled" ||
              value[0] === "identity.session.deactivated"
            ) {
              dispatch(setEnableUser(true));
              return;
            }

            message.error(t(value));
          } else {
            message.success(t(value));
          }
          //empty messages state
          dispatch(
            showAlert({
              message: "",
              type: "",
              code: "",
            })
          );
        }
      });
    } else {
      // message.error("Please wait!, Deployemnt is under progress.");
    }
  };

  // return <ToastContainer autoClose="2000" />;
}
export default withTranslation()(GoAlert);
